export const baseURL = 'https://virtualexpo.forumriskmanagement.it'
// export const baseURL = "https://pad1.forumriskmanagement.it";
// export const baseURL = "https://pad2.forumriskmanagement.it";

export const hostName = 'virtualexpo.forumriskmanagement.it'
// export const hostName = "pad1.forumriskmanagement.it";
// export const hostName = "pad2.forumriskmanagement.it";

// export const API_URL = '/api'
export const API_URL = 'https://api-revolution2022.forumriskmanagement.it/'
// export const API_URL = "https://api-pad1.forumriskmanagement.it/";
// export const API_URL = "https://api-pad2.forumriskmanagement.it/";
