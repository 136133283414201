import { hostName } from '@variables'

const scriptEle = document.createElement('script')
scriptEle.setAttribute('src', 'https://apis.google.com/js/api.js')
document.head.appendChild(scriptEle)

var analytics = {
  checkBrowser: () => {
    const { userAgent } = navigator
    let browserName

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome'
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox'
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari'
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera'
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge'
    } else {
      browserName = 'No browser detection'
    }

    if (
      browserName == 'chrome'
      || browserName == 'firefox'
      || browserName == 'edge'
    ) {
      return true
    }
    return false
  },

  checkDates: () => {
    const d1 = new Date()
    const d2 = new Date('2022', '07', '07')
    if (d1.getTime() < d2.getTime()) {
      return false
    }
    return true
  },

  getGoogleClient: () => new Promise((resolve, reject) => {
    if (!gapi.client) {
      gapi.load('client:auth2', () => {
        gapi.auth2
          .init({
            client_id:
                '685500425233-7c0hg009aklcf55ajqk0eemebdglhip1.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/analytics',
          })
          .then(() => {
            const google_auth = gapi.auth2.getAuthInstance()
            const user = google_auth.currentUser.get()
            if (
              user.hasGrantedScopes(
                'https://www.googleapis.com/auth/analytics',
              )
            ) {
              gapi.client
                .load(
                  'https://analyticsdata.googleapis.com/$discovery/rest?version=v1beta',
                )
                .then(
                  () => {
                    resolve()
                  },
                  err => {},
                )
            } else {
              google_auth.signIn().then(() => {
                // analytics.getGoogleClient();
                location.reload()
              })
            }
          })
      })
    } else {
      resolve()
    }
  }),

  defaultReport: () => new Promise((resolve, reject) => {
    analytics.getGoogleClient().then(() => {
      gapi.client.analyticsdata.properties
        .runReport({
          property: 'properties/294494185',
          resource: {
            dateRanges: [
              {
                startDate: '2022-11-21',
                endDate: '2022-11-24',
              },
            ],
            dimensions: [
              {
                name: 'eventName',
              },
            ],
            metrics: [
              {
                name: 'conversions',
                expression: 'eventCount',
              },
            ],
            dimensionFilter: {
              filter: {
                fieldName: 'hostName',
                stringFilter: {
                  matchType: 'CONTAINS',
                  value: hostName,
                },
              },
            },
          },
        })
        .then(data => {
          resolve(data.result.rows)
        })
        .catch(({ response: { data } }) => reject(data))
    })
  }),

  getReportForDateRanges: (start_date, endDate) => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: start_date,
              endDate,
            },
          ],
          dimensions: [
            {
              name: 'eventName',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'hostName',
              stringFilter: {
                matchType: 'CONTAINS',
                value: hostName,
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getDailyVisitorsDefaultReport: () => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: '2022-11-21',
              endDate: '2022-11-24',
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          orderBys: [
            {
              dimension: {
                dimensionName: 'date',
              },
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'eventName',
              stringFilter: {
                value: 'first_visit',
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getDailyInteractionsDefaultReport: () => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: '2022-11-21',
              endDate: '2022-11-24',
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          orderBys: [
            {
              dimension: {
                dimensionName: 'date',
              },
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'eventName',
              stringFilter: {
                value: 'page_view',
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getDailyVisitorsReportForDateRanges: (start_date, end_date) => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: start_date,
              endDate: end_date,
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          orderBys: [
            {
              dimension: {
                dimensionName: 'date',
              },
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'eventName',
              stringFilter: {
                value: 'first_visit',
              },
              fieldName: 'hostName',
              stringFilter: {
                matchType: 'CONTAINS',
                value: hostName,
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getDailyinteractionsReportForDateRanges: (start_date, end_date) => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: start_date,
              endDate: end_date,
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          orderBys: [
            {
              dimension: {
                dimensionName: 'date',
              },
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'eventName',
              stringFilter: {
                value: 'page_view',
              },
              fieldName: 'hostName',
              stringFilter: {
                matchType: 'CONTAINS',
                value: hostName,
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getDeviceCategoryDefaultReport: () => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: '2022-11-21',
              endDate: '2022-11-24',
            },
          ],
          dimensions: [
            {
              name: 'deviceCategory',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'hostName',
              stringFilter: {
                matchType: 'CONTAINS',
                value: hostName,
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getDeviceCategoryReportForDateRanges: (startDate, end_date) => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate,
              endDate: end_date,
            },
          ],
          dimensions: [
            {
              name: 'deviceCategory',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
        },
        dimensionFilter: {
          filter: {
            fieldName: 'hostName',
            stringFilter: {
              matchType: 'CONTAINS',
              value: hostName,
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getReportSingleStand: filter_list => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: '2022-11-21',
              endDate: '2022-11-24',
            },
          ],
          dimensions: [
            {
              name: 'eventName',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'eventName',
              inListFilter: {
                values: filter_list,
              },
              fieldName: 'hostName',
              stringFilter: {
                matchType: 'CONTAINS',
                value: hostName,
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getReportSingleStandForDateRanges: (list_filter, start_date, end_date) => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: start_date,
              endDate: end_date,
            },
          ],
          dimensions: [
            {
              name: 'eventName',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'eventName',
              inListFilter: {
                values: list_filter,
              },
              fieldName: 'hostName',
              stringFilter: {
                matchType: 'CONTAINS',
                value: hostName,
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getDailyVisitorsSingleStand: id => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: '2022-11-21',
              endDate: '2022-11-24',
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          orderBys: [
            {
              dimension: {
                dimensionName: 'date',
              },
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'eventName',
              stringFilter: {
                value: `visit_stand_${id}`,
              },
              fieldName: 'hostName',
              stringFilter: {
                matchType: 'CONTAINS',
                value: hostName,
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getDailyVisitorsReportForDateRangesSingleStand: (
    id,
    start_date,
    end_date,
  ) => new Promise((resolve, reject) => {
    gapi.client.analyticsdata.properties
      .runReport({
        property: 'properties/294494185',
        resource: {
          dateRanges: [
            {
              startDate: start_date,
              endDate: end_date,
            },
          ],
          dimensions: [
            {
              name: 'date',
            },
          ],
          metrics: [
            {
              name: 'conversions',
              expression: 'eventCount',
            },
          ],
          orderBys: [
            {
              dimension: {
                dimensionName: 'date',
              },
            },
          ],
          dimensionFilter: {
            filter: {
              fieldName: 'eventName',
              stringFilter: {
                value: `visit_stand_${id}`,
              },
              fieldName: 'hostName',
              stringFilter: {
                matchType: 'CONTAINS',
                value: hostName,
              },
            },
          },
        },
      })
      .then(data => {
        resolve(data.result.rows)
      })
      .catch(({ response: { data } }) => reject(data))
  }),

  getReportDeviceCategoriesSingleStand(id) {
    return new Promise((resolve, reject) => {
      gapi.client.analyticsdata.properties
        .runReport({
          property: 'properties/294494185',
          resource: {
            dateRanges: [
              {
                startDate: '2022-11-21',
                endDate: '2022-11-24',
              },
            ],
            dimensions: [
              {
                name: 'deviceCategory',
              },
            ],
            metrics: [
              {
                name: 'conversions',
                expression: 'eventCount',
              },
            ],
            dimensionFilter: {
              filter: {
                fieldName: 'eventName',
                inListFilter: {
                  values: [`visit_stand_${id}`],
                },
                fieldName: 'hostName',
                stringFilter: {
                  matchType: 'CONTAINS',
                  value: hostName,
                },
              },
            },
          },
        })
        .then(data => {
          resolve(data.result.rows)
        })
        .catch(({ response: { data } }) => reject(data))
    })
  },

  getReportDeviceCategoriesForDateRangesSingleStand(id, start_date, end_date) {
    return new Promise((resolve, reject) => {
      gapi.client.analyticsdata.properties
        .runReport({
          property: 'properties/294494185',
          resource: {
            dateRanges: [
              {
                startDate: start_date,
                endDate: end_date,
              },
            ],
            dimensions: [
              {
                name: 'deviceCategory',
              },
            ],
            metrics: [
              {
                name: 'conversions',
                expression: 'eventCount',
              },
            ],
            dimensionFilter: {
              filter: {
                fieldName: 'eventName',
                inListFilter: {
                  values: [`visit_stand_${id}`],
                },
                fieldName: 'hostName',
                stringFilter: {
                  matchType: 'CONTAINS',
                  value: hostName,
                },
              },
            },
          },
        })
        .then(data => {
          resolve(data.result.rows)
        })
        .catch(({ response: { data } }) => reject(data))
    })
  },

  getReportInteractionsPinpointsSingleStand(filter_list) {
    if (filter_list.length > 0) {
      return new Promise((resolve, reject) => {
        gapi.client.analyticsdata.properties
          .runReport({
            property: 'properties/294494185',
            resource: {
              dateRanges: [
                {
                  startDate: '2022-11-21',
                  endDate: '2022-11-24',
                },
              ],
              dimensions: [
                {
                  name: 'eventName',
                },
              ],
              metrics: [
                {
                  name: 'conversions',
                  expression: 'eventCount',
                },
              ],
              dimensionFilter: {
                filter: {
                  fieldName: 'eventName',
                  inListFilter: {
                    values: filter_list,
                  },
                  fieldName: 'hostName',
                  stringFilter: {
                    matchType: 'CONTAINS',
                    value: hostName,
                  },
                },
              },
            },
          })
          .then(data => {
            resolve(data.result.rows)
          })
          .catch(({ response: { data } }) => reject(data))
      })
    }
  },
  getReportInteractionsPinpointsForDateRangesSingleStand(
    start_date,
    end_date,
    filter_list,
  ) {
    if (filter_list.length > 0) {
      return new Promise((resolve, reject) => {
        gapi.client.analyticsdata.properties
          .runReport({
            property: 'properties/294494185',
            resource: {
              dateRanges: [
                {
                  startDate: start_date,
                  endDate: end_date,
                },
              ],
              dimensions: [
                {
                  name: 'eventName',
                },
              ],
              metrics: [
                {
                  name: 'conversions',
                  expression: 'eventCount',
                },
              ],
              dimensionFilter: {
                filter: {
                  fieldName: 'eventName',
                  inListFilter: {
                    values: filter_list,
                  },
                  fieldName: 'hostName',
                  stringFilter: {
                    matchType: 'CONTAINS',
                    value: hostName,
                  },
                },
              },
            },
          })
          .then(data => {
            resolve(data.result.rows)
          })
          .catch(({ response: { data } }) => reject(data))
      })
    }
  },
}
export default analytics
